import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import PlansTable from "../../components/PlansTable/PlansTable"
import "./Features.css"
import Services from "../../components/Services/Services"
import CallToAction from "../../components/CallToAction/CallToAction"
import httpAuthIcon from "../../images/http-auth-icon.svg"
import imageAuthIcon from "../../images/image-auth-icon.svg"
import ipAddrIcon from "../../images/ip-address-icon.svg"
import mwPermissionsIcon from "../../images/mw-permissions-icon.svg"
import editorIcon from "../../images/editor.svg"
import addExtensionsIcon from "../../images/add-extensions-icon.svg"
import forgetAdsIcon from "../../images/forget-ads-icon.svg"
import selfServiceIcon from "../../images/self-service-icon.svg"
import moveWikiIcon from "../../images/move-wiki-icon.svg"
import ssoIcon from "../../images/sso-icon.svg"
import antiSpamSecurityIcon from "../../images/anti-spam-security-icon.svg"
import typingBubbleIcon from "../../images/typing-bubble-icon.svg"
import techSupportIcon from "../../images/tech-support-icon.svg"
import { useTranslation } from "react-i18next"

const Features = () => {

	const { t } = useTranslation()

	return (
		<div className="features-container">
			<Helmet>
				<title>Features | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section heading-primary-light">
				{t("features.heading")}
			</div>
			<div className="statement-banner features-statement-banner">
				<div className="heading-secondary-light">
					{t("features.heading-paragraph")}
				</div>
				<p className="subheading-primary-light">
					{t("features.heading-offer")}
				</p>
			</div>
			<div className="features-privacy-container">
				<div className="features-privacy-statement">
					<h3 className="features-privacy-heading">
						{t("features.privacy.heading")}{" "}
						<span className="color-primary">
							{t("features.privacy.heading-color")}
						</span>{" "}
						{t("features.privacy.heading-sub")}
					</h3>
					<p>
						{t("features.privacy.paragraph")}
					</p>
				</div>

				<div className="features-privacy-body body-text-dark">
					<div className="features-privacy-box">
						<div className="features-privacy-box-img-container">
							<img
								src={httpAuthIcon}
								alt="http-auth-icon"
								className="privacy-img"
							/>
						</div>
						<h3 className="features-privacy-box-title color-quaternary">
							{t("features.privacy.box1-title")}
						</h3>
						<p>
							{t("features.privacy.box1-explanation")}
						</p>
					</div>
					<div className="features-privacy-box">
						<div className="features-privacy-box-img-container">
							<img
								src={imageAuthIcon}
								alt="http-auth-icon"
								className="privacy-img"
							/>
						</div>
						<h3 className="features-privacy-box-title color-quaternary">
							{t("features.privacy.box2-title")}
						</h3>
						<p>
							{t("features.privacy.box2-explanation")}
						</p>
					</div>
					<div className="features-privacy-box">
						<div className="features-privacy-box-img-container">
							<img
								src={ipAddrIcon}
								alt="http-auth-icon"
								className="privacy-img"
							/>
						</div>
						<h3 className="features-privacy-box-title color-quaternary">
							{t("features.privacy.box3-title")}
						</h3>
						<p>
							{t("features.privacy.box3-explanation")}
						</p>
					</div>
					<div className="features-privacy-box">
						<div className="features-privacy-box-img-container">
							<img
								src={mwPermissionsIcon}
								alt="http-auth-icon"
								className="privacy-img"
							/>
						</div>
						<h3 className="features-privacy-box-title color-quaternary">
							{t("features.privacy.box4-title")}
						</h3>
						<p>
							{t("features.privacy.box4-explanation")}
						</p>
					</div>
				</div>
				<br />
				<p className="body-text-dark">
					{t("features.privacy.explanation")}{" "}<br />
					<b className="color-primary">{t("features.privacy.explanation-bold")}</b>{" "}
					{t("features.privacy.explanation-sub")}
				</p>
				<br />
				<p>
					{t("features.privacy.explanation-offer")}
				</p>
				<br />
				<Link to="/privacy" className="button-tertiary">
					{t("features.privacy.explore-button")}
				</Link>
			</div>
			<div className="features-editor-container">
				<div className="features-editor">
					<div>
						<img src={editorIcon} alt="visual-editor" />
					</div>
					<div className="features-editor-text">
						<h3 className="features-editor-heading">
							<span className="color-quaternary">
								{t("features.visualeditor.title-color")}
							</span>{" "}
							{t("features.visualeditor.title")}
						</h3>
						<p>
							{t("features.visualeditor.explanation")}
						</p>
						<a
							href="https://www.mediawiki.org/wiki/VisualEditor:Test?veaction=edit"
							className="button-quaternary smaller-btn"
						>
							{t("features.visualeditor.button")}
						</a>
					</div>
				</div>
			</div>
			<div className="features">
				<div className="feature-box-left feature-1">
					<div className="feature-box-text">
						<h2>
							{t("features.extensions.title")}
						</h2>
						<p>
							{t("features.extensions.paragraph1")}
						</p>
						<p>
							{t("features.extensions.paragraph2")}
						</p>
						<Link
							to="/extensions"
							className="button-quaternary smaller-btn"
						>
							{t("features.extensions.button")}
						</Link>
					</div>
					<div className="feature-box-img-container">
						<img className="feature-box-img" src={addExtensionsIcon} alt="add-extensions-icon" />
					</div>
				</div>
				<div className="feature-box-right feature-2">
					<div className="feature-box-img-container">
						<img className="feature-box-img" src={forgetAdsIcon} alt="forget-ads-icon" />
					</div>
					<div className="feature-box-text">
						<h2>
							{t("features.ads.title1")} <br />
							{t("features.ads.title2")}
						</h2>
						<p>
							{t("features.ads.paragraph")}
						</p>
					</div>
				</div>
				<div className="feature-box-left feature-3">
					<div className="feature-box-text">
						<h2>
							{t("features.wiki-settings.title")}
						</h2>
						<p>
							{t("features.wiki-settings.explanation")}{" "}
							<b>{t("features.wiki-settings.explanation-bold")}</b>.
						</p>
					</div>
					<div className="feature-box-img-container">
						<img className="feature-box-img" src={selfServiceIcon} alt="self-service-icon" />
					</div>
				</div>
				<div className="feature-box-right feature-4">
					<div className="feature-box-img-container">
						<img className="feature-box-img" src={moveWikiIcon} alt="move-wiki-icon" />
					</div>
					<div className="feature-box-text">
						<h2>
							{t("features.moving-in.title")}
						</h2>
						<p>
							{t("features.moving-in.paragraph1")} <br />
							<br />
							{t("features.moving-in.paragraph2")}
						</p>
					</div>
				</div>
				<div className="feature-box-left feature-5">
					<div className="feature-box-text">
						<h2>
							{t("features.sso.title")}
						</h2>
						<p>
							{t("features.sso.paragraph1")} <br />
							<br />
							{t("features.sso.paragraph2")}
						</p>
						<Link to="/contact" className="button-quaternary smaller-btn">
							{t("features.sso.button")}
						</Link>
					</div>
					<div className="feature-box-img-container">
						<img className="feature-box-img" src={ssoIcon} alt="sso-icon" />
					</div>
				</div>
				<div className="feature-box-right feature-6">
					<div className="feature-box-img-container">
						<img className="feature-box-img" src={antiSpamSecurityIcon} alt="anti-spam-security-icon" />
					</div>
					<div className="feature-box-text">
						<h2>
							{t("features.security.title")}
						</h2>
						<p>
							{t("features.security.explanation")}{" "}
							<b>
								{t("features.security.explanation-bold")}
							</b>
							.
						</p>
					</div>
				</div>
			</div>
			<div className="features-support">
				<h2 className="heading-secondary-light">
					{t("features.support.title")}
				</h2>
				<div className="support-text">
					<p>
						{t("features.support.paragraph1")}
						<br />
						<br />
						{t("features.support.paragraph2")}
					</p>
				</div>
				<Link to="/contact" className="button-primary-light">
					<img
						alt="typing-bubble"
						src={typingBubbleIcon}
						className="typing-bubble-icon"
					/>
					{t("features.support.button")}
				</Link>
				<img
					className="tech-support-icon"
					src={techSupportIcon}
					alt="tech-sup"
				/>
			</div>
			<div className="plans-container">
				<div className="heading-primary-light">
					{t("features.plans")}
				</div>
				<PlansTable />
			</div>
			<Services />
			<CallToAction />
		</div>
	)
}

export default Features