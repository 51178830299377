import React, { useState } from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./Login.css"
import {useTranslation} from "react-i18next";

const Login = () => {
	const { t } = useTranslation()

	const [wikiID, setWikiID] = useState("")

	const handleLoginWiki = (e) => {
		e.preventDefault()
		if (wikiID === "" || wikiID === undefined || wikiID === null) return
		window.location.href = `https://${wikiID}.mywikis.wiki/wiki/Special:UserLogin`
	}

	return (
		<div className="login-container">
			<Helmet>
				<title>Log in | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">
				{t("login.title")}
			</div>
			<div className="login">
				<div className="login-half">
					<div className="login-box-1">
						<div className="heading-secondary-dark">
							{t("login.login-to-wiki")}
						</div>
						<form onSubmit={handleLoginWiki}>
							<label>{t("login.enter-wiki-ID")}</label>
							<input
								className="loginwiki-input"
								type="text"
								onChange={(e) => setWikiID(e.target.value)}
							/>
							<p className="login-subtext">
								{t("login.login-subtext")}
							</p>
							<button className="button-primary loginwiki-btn" type="submit">
								{t("login.login-button-primary")}
							</button>
						</form>
						<p>
							{t("login.don't-have-a-wiki")}{" "}
							<Link to="/createawiki" className="button-link-blue">
								{t("login.don't-have-a-wiki-subtext")}
							</Link>
						</p>
					</div>
				</div>
				<div className="login-half">
					<div className="login-box-2">
						<div className="heading-secondary-dark">
							{t("login.client-panel-title")}{" "}
							<span className="color-quaternary">
								{t("login.client-panel-title-link")}
							</span>
						</div>
						<p className="login-subtext">
							{t("login.client-panel-subtext")}
						</p>
						<a
							className="button-green"
							href="https://panel.mywikis.eu/clientarea.php"
						>
							{t("login.client-panel-button")}
						</a>
					</div>
					<div className="login-box-2">
						<div className="heading-secondary-dark">
							{t("login.self-service-title")}{" "}
							<span className="color-quaternary">
								{t("login.self-service-title-link")}
							</span>
						</div>
						<p className="login-subtext">
							{t("login.self-service-subtext")}
						</p>
						<a
							className="button-green"
							href="https://selfservice.mywikis.eu/"
						>
							{t("login.self-service-button")}
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login