import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import "./Landing.css"
import TestimonialCarousel from "../../components/TestimonialCarousel/TestimonialCarousel"
import Typewriter from "typewriter-effect"
import HeroCarousel from "../../components/HeroCarousel/HeroCarousel"
import PlansTable from "../../components/PlansTable/PlansTable"
import heroLaptopIcon from "../../images/hero-laptop-icon.svg"
import wingstopLogo from "../../images/wingstop-logo.jpeg"
import kabbeeLogo from "../../images/kabbee-logo.jpeg"
import univcopenhagenLogo from "../../images/univcopenhagen-logo.png"
import appfolioLogo from "../../images/appfolio-logo.png"
import soylentLogo from "../../images/soylent-logo.png"
import towsonuLogo from "../../images/towsonu-logo.png"
import harvardLogo from "../../images/harvard-logo.jpeg"
import libertarianLogo from "../../images/libertarian-banner-logo.png"
import cieeLogo from "../../images/ciee-logo.jpeg"
import rheinwaalLogo from "../../images/rheinwaal-logo.jpeg"
import aprilaireLogo from "../../images/aprilaire-blue-color-logo.png"
import winonastateLogo from "../../images/logo-winonastate.png"
import unlimitedResourcesIcon from "../../images/unlimited-resources-icon.svg"
import noAdsIcon from "../../images/no-ads-icon.svg"
import freeExtensionsIcon from "../../images/free-extensions-icon.svg"
import mwPermissionsIcon from "../../images/mw-permissions-icon.svg"
import selfServiceIcon from "../../images/self-service-icon.svg"
import ssoIcon from "../../images/sso-icon.svg"
import antiSpamSecurityIcon from "../../images/anti-spam-security-icon.svg"
import { useTranslation } from "react-i18next"

const Landing = () => {
	const { t } = useTranslation()

	return (
		<div className="landing-container">
			<Helmet>
				<title>MyWikis Europe | Start your wiki today | MediaWiki hosting</title>
			</Helmet>
			<div className="hero">
				<HeroCarousel />
				<img
					className="hero-laptop-icon"
					src={heroLaptopIcon}
					alt="laptop icon"
				/>
			</div>
			<div className="statement-banner heading-primary-light">
				{t("landing.europe.heading")}
			</div>
			<div class="generic-text-block">
				<div class="heading-secondary-dark"><p>MediaWiki hosting for Europeans in Europe.</p></div>
				<p>
					MyWikis Europe (MyWikis.eu) offers MediaWiki hosting based in Vienna, Austria. With all pricing in euros and full GDPR compliance,{" "}
					you can enjoy the same great features of MyWikis you've come to know and love, with the comforts of home offered by the EU.{" "}
					Sign up today!
				</p>
			{/* </div>
			<div className="statement-banner-align-only gradient-section-light heading-primary-light">
				{t("landing.europe.faq.title")}
			</div>
			<div class="generic-text-block"> */}
				<div class="learn-more-2">
					<Link to="/faq" className="button-tertiary usecases-btn">
						{t("landing.europe.faq.button")}
					</Link>{" "}
					<Link to="/createawiki" className="button-tertiary compare-btn">
						{t("landing.hero.start-using.link1")}
					</Link>
				</div>
			</div>
			<div className="statement-banner heading-primary-light">
				{t("landing.good-company.heading")}
			</div>
			<div className="partners-container">
				<div className="partners-text">
					{t("landing.partners.headings.prefix")}{" "}
					<Typewriter
						options={{
							strings: [
								t("landing.partners.headings.suffix1"),
								t("landing.partners.headings.suffix2"),
								t("landing.partners.headings.suffix3"),
								t("landing.partners.headings.suffix4"),
								t("landing.partners.headings.suffix5"),
								t("landing.partners.headings.suffix6"),
								t("landing.partners.headings.suffix7"),
								t("landing.partners.headings.suffix8"),
							],
							pauseFor: 2000,
							delay: 60,
							deleteSpeed: 20,
							autoStart: true,
							loop: true,
						}}
					/>
				</div>
				<div className="partners-icons-container">
					<div className="partner-icon">
						<img alt="wingstop logo" src={wingstopLogo} height="90px" />
					</div>
					<div className="partner-icon">
						<img alt="kabbee logo" src={kabbeeLogo} height="90px" />
					</div>
					<div className="partner-icon">
						<img
							alt="univcopenhagen logo"
							src={univcopenhagenLogo}
							height="90px"
						/>
					</div>
					<div className="partner-icon">
						<img alt="appfolio logo" src={appfolioLogo} height="50px" />
					</div>
					<div className="partner-icon">
						<img alt="soylent logo" src={soylentLogo} height="90px" />
					</div>
					<div className="partner-icon">
						<img alt="towsonu logo" src={towsonuLogo} height="70px" />
					</div>
					<div className="partner-icon">
						<img alt="harvard logo" src={harvardLogo} height="70px" />
					</div>
					<div className="partner-icon">
						<img alt="libertarian logo" src={libertarianLogo} height="70px" />
					</div>
					<div className="partner-icon">
						<img alt="ciee logo" src={cieeLogo} height="90px" />
					</div>
					<div className="partner-icon">
						<img alt="rheinwaal logo" src={rheinwaalLogo} height="110px" />
					</div>
					<div className="partner-icon">
						<img alt="aprilaire logo" src={aprilaireLogo} height="70px" />
					</div>
					<div className="partner-icon">
						<img alt="winonastate logo" src={winonastateLogo} height="100px" />
					</div>
				</div>
			</div>

			<div className="testimonial-container">
				<div className="people-said-text heading-primary-light">
					{t("landing.testimonials.heading")}
				</div>
				<TestimonialCarousel />
			</div>
			<div className="explore-features-container">
				<div className="explore-features">
					<div className="explore-feature-box">
						<h3>
							{t("landing.explore-features.unlimited-resources.heading")}
						</h3>
						<div className="explore-feature-box-img-container">
							<img
								src={unlimitedResourcesIcon}
								alt="unlimited-resources-icon"
							/>
						</div>
						<p>
							{t("landing.explore-features.unlimited-resources.description")}
						</p>
					</div>
					<div className="explore-feature-box">
						<h3>{t("landing.explore-features.no-ads.heading")}</h3>
						<div className="explore-feature-box-img-container">
							<img
								src={noAdsIcon}
								alt="no-ads-icon"
							/>
						</div>
						<p>{t("landing.explore-features.no-ads.description")}</p>
					</div>
					<div className="explore-feature-box">
						<h3>{t("landing.explore-features.free-extensions.heading")}</h3>
						<div className="explore-feature-box-img-container">
							<img
								src={freeExtensionsIcon}
								alt="free-extensions-icon"
							/>
						</div>
						<p>{t("landing.explore-features.free-extensions.description")}</p>
					</div>
					<div className="explore-feature-box">
						<h3>{t("landing.explore-features.privacy-levels.heading")}</h3>
						<div className="explore-feature-box-img-container">
							<img
								src={mwPermissionsIcon}
								alt="http-auth-icon"
								class="explore-feature-box-img"
							/>
						</div>
						<p>{t("landing.explore-features.privacy-levels.description")}</p>
					</div>
					<div className="explore-feature-box">
						<h3>{t("landing.explore-features.self-service.heading")}</h3>
						<div className="explore-feature-box-img-container">
							<img
								src={selfServiceIcon}
								alt="http-auth-icon"
								class="explore-feature-box-img"
							/>
						</div>
						<p>{t("landing.explore-features.self-service.description")}</p>
					</div>
					<div className="explore-feature-box">
						<h3>{t("landing.explore-features.anti-spam.heading")}</h3>
						<div className="explore-feature-box-img-container">
							<img
								src={antiSpamSecurityIcon}
								alt="http-auth-icon"
								class="explore-feature-box-img"
							/>
						</div>
						<p>{t("landing.explore-features.anti-spam.description")}</p>
					</div>
					<div className="explore-feature-box">
						<h3>{t("landing.explore-features.sso.heading")}</h3>
						<div className="explore-feature-box-img-container">
							<img
								src={ssoIcon}
								alt="http-auth-icon"
								class="explore-feature-box-img"
							/>
						</div>
						<p>{t("landing.explore-features.sso.description")}</p>
					</div>

				</div>
				<br />
				<br />
				<Link to="/features" className="button-tertiary">
					{t("landing.explore-features.link1")}
				</Link>
			</div>
			<div className="plans-container">
				<div className="heading-primary-light">{t("landing.plans")}</div>
				<PlansTable />
			</div>
		</div>
	)
}

export default Landing
