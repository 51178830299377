import React from "react"
import Helmet from "react-helmet/es/Helmet"
import "./GDPR.css"
import { useTranslation } from "react-i18next"

const GDPR = () => {
    const { t } = useTranslation()

	return (
		<div>
			<Helmet>
				<title>GDPR Compliance | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">{t("gdpr.heading.title")}</div>
			<div className="terms-container">
				<p>Last updated: 19 October 2023</p>

				<p>MyWikis Europe is operated inside the European Union in compliance with the General Data Protection Regulation (GDPR).</p>

                <h3>Where your information resides</h3>
                <p>Your personal information is stored at rest on our servers in Vienna, Austria.</p>
                <p>Media files uploaded to wikis are stored at rest in Frankfurt am Main, Germany.</p>
                <p>Emails sent to users from wikis are sent via email servers in Frankfurt am Main, Germany.</p>

                <h3>Protections for sensitive information</h3>
                <p>
					In addition to the obvious restriction that no content violating EU laws can be hosted on MyWikis.eu,{" "}
					we are additionally categorically prohibited by the GDPR from hosting any of the following personal data:{" "}
                    </p>
                    <ul>
                        <li>racial or ethnic origin;{" "}</li>
                        <li>sexual orientation;{" "}</li>
                        <li>political opinions;{" "}</li>
                        <li>religious or philosophical beliefs;{" "}</li>
                        <li>trade-union membership;{" "}</li>
                        <li>genetic, biometric, or health data;{" "}</li>
                        <li>personal data relating to criminal convictions and offences.</li>
                    </ul>
				<p>
					While GDPR allows derogations to be made to host such content, we have decided against accepting{" "}
					such content on MyWikis.eu wikis. Instead, you can use MyWikis.com, which is governed by{" "}
					U.S. laws and where some of what is considered personal data in the EU are in fact generally considered free{" "}
					speech under the First Amendment of the U.S. Constitution and are thus protected at{" "}
					a fundamental level of American law.
				</p>

                <h3>Your data rights as a GDPR subject</h3>
                <p>
                    You can see all data we have on you (except for the IP addresses the wikis have collected) through your preferences/profile{" "}
                    pages on the respective services. You can request for a history of the IP addresses we have tracked you using on our wikis{" "}
                    by placing a request on our support system; however, please note that in any wiki that does not have the CheckUser extension{" "}
					installed, we do not track your IP address at all. Even if the wiki has the CheckUser extension installed, we do not use this{" "}
					information actively except for spam prevention or doppelganger account investigations. However, you are most welcome to request{" "}
					for us to delete such information unless the authorities inform us this information must be retained. You can have us transfer this info{" "}
                    to yourself or a third party. You can ask for clarification on how we use your personal information (although an explanation{" "}
                    already exists in the "Your data" section of this privacy policy). You can rectify incorrect data we have on you. You can ask us{" "}
                    to stop using your personal information to market to you (unsubscribing from our email list that we seldom use). You can ask us to{" "}
                    keep your data but stop processing it. And lastly, you can object to our practices.
                </p>
				<p>
					To exercise your rights, please visit our{" "}
					<a
						href="https://helpcenter.mywikis.wiki/wiki/Data_subject_rights"
						target="_blank"
						rel="noreferrer">
						data subject rights page on the MyWikis Help Center
					</a>.
				</p>

                <h3>Sub-processors of data</h3>
                <p>
                    We work with mission-critical partners to provide the infrastructure needed for MyWikis to provide you with our services.{" "}
                    All of these sub-processors of data are themselves compliant with GDPR.{" "}
                    We only work with trusted partners when we absolutely need to.
                </p>
                <p>Below is a list of the organizations who are sub-processors of your data and the location(s) at which they process your data (if known):</p>
                <ul>
                    <li><a href="https://www.ipax.at/">IPAX.at</a> &mdash; Vienna, Austria</li>
                    <li><a href="https://www.wasabi.com/">Wasabi</a> (<a href="https://cdn.brandfolder.io/BFD8NB05/at/6q6b6gs5697tmcxktm3fxmkz/GDPR_white_paper_22Jan2022.pdf">GDPR whitepaper</a>) &mdash; Frankfurt am Main, Germany</li>
                    <li><a href="https://aws.amazon.com/">Amazon Web Services</a> &mdash; Frankfurt am Main, Germany</li>
                    <li><a href="https://www.stripe.com/">Stripe</a> (<a href="https://support.stripe.com/questions/personal-data-subject-access-requests-for-eu-residents-under-gdpr">GDPR requests</a>)</li>
                </ul>

                <h2>GDPR policy</h2>

                <p>
                    Our Privacy Policy is included in our Terms of Service (secion 10), please visit{" "}
                    <a href="https://www.mywikis.eu/terms">mywikis.eu/terms</a>.
                </p>

               	<p>
					For more information regarding data subject rights at MyWikis, including
					how to exercise your rights, please visit our{" "}
					<a
						href="https://helpcenter.mywikis.wiki/wiki/Data_subject_rights"
						target="_blank"
						rel="noreferrer">
						data subject rights page on the MyWikis Help Center
					</a>.
				</p>
				<h3>Accountability and management</h3>
				<p>
					Data breaches will be reported within 72 hours of discovery
                    via our Twitter account @MyWikis.
				</p>
				<h3>Data rights</h3>
				<p>
					You can see all data we have on you (except for the IP addresses the
					wikis have collected) through your preferences/profile pages on the
					respective services. You can request for a history of the IP addresses
					we have tracked you using on our wikis by placing a request on our
					support system. While we do not use this information actively except
					for spam prevention or doppelganger account investigations, you may
					request for us to delete such information.
				</p>
				<p>
					You can have us transfer this info to yourself or a third party. You
					can ask for clarification on how we use your personal information
					(although an explanation already exists in the "Your data" section of
					this privacy policy). You can rectify incorrect data we have on you.
					You can ask us to stop using your personal information to market to
					you (unsubscribing from our email list that we seldom use). You can
					ask us to keep your data but stop processing it. And lastly, you can
					object to our practices.
				</p>
				<p>
					You may use this web-based form to exercise your GDPR rights:{" "}
					<a href="privacylaws">data privacy wizard</a>.
				</p>
			</div>
		</div>
	)
}

export default GDPR
