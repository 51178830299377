import React from "react"
import { Link } from "react-router-dom"
import "./PlansTable.css"
import plansInfo from "../../utils/plansinfo"
import greenCheckmark from "../../images/green-checkmark.svg"

const PlansTable = (props) => {
	const plans =
		props.plans === undefined
			? ["Foundation", "Pro", "Quantum", "Ultra"]
			: props.plans
	const features = [
		"Unlimited Pages",
		"Unlimited Users",
		"No Ads",
		"HTTPS Protection",
		"No MyWikis Branding",
		"All Privacy Tiers",
		"Automatic Free MediaWiki Upgrades",
		"Content Licensing Customization",
		"Mobile Site Support",
		"50+ Extensions Pre-Installed",
		"Add Extensions For Free",
		"Add Skins For Free",
        "Daily Automatic Backups",
		"MyWikis Self Service Portal",
		"Free Subdomain",
		"Bring Your Own Domain",
		"Visual Editor",
		"Enhanced Search (CirrusSearch)",
		"Template Import Services",
		"Semantic MediaWiki",
		"Matomo Analytics",
		"SSO Integration",
		"Mass Add Users",
		"HTTP Auth/IP allowlist",
		"SSH Access",
		"Bespoke Extension and Skin Files",
		"MediaWiki Version",
		"Included Wikis",
		"Price Per Additional Wiki",
		"File Storage",
		"Best For",
		"Monthly Pricing",
		"Annual Pricing",
	]

	return (
		<div className="plans-table-container">
			<div className="plans-table-row">
				<div className="plans-table-header-cell cell-hide">
					<div className="plan-title">&#8203;</div>
					<div className="plan-description-container" />
				</div>
				{plans.map((plan, index) => (
					<div
						key={index}
						className={
							(plan === "Pro" || plan === "Business Pro")
								? "column-pro plans-table-header-cell"
								: "plans-table-header-cell"
						}
					>
						<div className="plan-title">
							{plan}
							{(plan === "Pro" || plan === "Business Pro") && (
								<div className="recommended-banner">Recommended</div>
							)}
						</div>
						<div className="plan-description-container">
							{plansInfo[plan]["Starting at"] === true && (
								<p className="plan-cost-starting-at">
									Starting at
								</p>
							)}
							<span className="plan-cost">
								{plansInfo[plan]["Dollars Places"]}
								<span className="plan-cost-cents">
									{plansInfo[plan]["Cents Places"]}
								</span>
							</span>
							<br />
							<span className="plan-cost-description">
								{plansInfo[plan]["Cost Description"]}
							</span>
							<p className="plan-description">
								{plansInfo[plan]["Plan Description"]}
							</p>
						</div>
					</div>
				))}
			</div>
			{features.map((feature, ind) => (
				<React.Fragment key={ind}>
					<div className="feature-name">{feature}</div>
					<div className="plans-table-row">
						<div className="plans-table-cell" id="feature-name-inline">
							{feature}
						</div>
						{plans.map((plan, index) => {
							if (plansInfo[plan][feature] === true) {
								return (
									<div
										key={index}
										className={
											(plan === "Pro" || plan === "Business Pro")
												? "plans-table-cell column-pro"
												: "plans-table-cell"
										}
									>
										<div className="plans-table-cell-title">
											{plan}
											<br />
										</div>
										<img
											className="green-checkmark"
											src={greenCheckmark}
											alt="green-checkmark"
										/>
									</div>
								)
							} else if (plansInfo[plan][feature] === false) {
								return (
									<div
										key={index}
										className={
											(plan === "Pro" || plan === "Business Pro")
												? "plans-table-cell column-pro"
												: "plans-table-cell"
										}
									>
										<div className="plans-table-cell-title">
											{plan}
											<br />
										</div>{" "}
										&#8203;{" "}
									</div>
								)
							} else {
								return (
									<div
										key={index}
										className={
											(plan === "Pro" || plan === "Business Pro")
												? "plans-table-cell column-pro"
												: "plans-table-cell"
										}
									>
										<div className="plans-table-cell-title">
											{plan}
											<br />
										</div>{" "}
										<span className="cell-text">
										{plansInfo[plan][feature]}</span>
									</div>
								)
							}
						})}
					</div>
				</React.Fragment>
			))}
			<div className="plans-table-btn-row">
				<div className="plans-table-cell" id="plans-btn-hide">&#8203;</div>
				{plans.map((plan, index) => (
					<div
						key={index}
						className={
							(plan === "Pro" || plan === "Business Pro")
								? "plans-table-cell column-pro"
								: "plans-table-cell"
						}
					>
						<a
							href={plansInfo[plan]["Get Link"]}
							className="button-primary select-plan-btn"
						>
							Get {plan}
						</a>
					</div>
				))}
			</div>
			<br />
			<p className="color-quaternary">
				VPS options available for Ultra plans; see{" "}
				<Link to="/vps" className="button-link-blue">
					our VPS information page
				</Link>{" "}
				for full details. Some differences apply to VPS plans. Please see{" "}
				<Link to="/compare-signature-and-vps-plans" className="button-link-blue">
					our detailed comparison
				</Link>{" "}
				for full details.
			</p>
			<p className="color-quaternary">
				After-tax prices will differ based on geographic region. Use the{" "}
				<Link to="/pricecalculator" className="button-link-blue">
					price calculator
				</Link>{" "}
				to calculate your local price.
			</p>
			<p className="color-quaternary">
				Migrations are complimentary for simple wikis; expedited options available for{" "}
				purchase. Simple SSO setup is complimentary but more complex SSO setup may{" "}
				require one-time fee depending on complexity of requirements. See the{" "}
				<Link to="/sso-information" className="button-link-blue">
					SSO help page
				</Link>{" "}
				for more information.
			</p>
		</div>
	)
}

export default PlansTable
