const currency_symbols = {
	EUR: "€",
	GBP: "£",
}

const products = {
	foundation: "MyWikis Foundation",
	pro: "MyWikis Pro",
	corporate: "MyWikis Corporate",
	vps: "MyWikis VPS",
}

const addon_names = {
	backupsplus: "Backups Plus",
	prioritysupport: "Priority Support",
	extrastorage: "Extra Storage",
	extrawikis: "Extra Wikis",
}

const price_list = {
	EUR: {
		foundation: 1199,
		pro: 1799,
		quantum: 2999,
		ultra: 4799,
	},
}

const addons_price_list = {
	EUR: {
		backupsplus: 500,
		prioritysupport: 399,
		extrastorage: 10,
		extrawikis: 500,
	},
}

export const countriesMap = {
	AT: "Austria",
	BE: "Belgium",
	BG: "Bulgaria",
	CY: "Cyprus",
	CZ: "Czechia",
	DE: "Germany",
	DK: "Denmark",
	EE: "Estonia",
	ES: "Spain",
	FI: "Finland",
	FR: "France",
	GB: "United Kingdom",
	GR: "Greece",
	HR: "Croatia",
	HU: "Hungary",
	IE: "Ireland",
	IT: "Italy",
	LT: "Lithuania",
	LU: "Luxembourg",
	LV: "Latvia",
	MT: "Malta",
	NL: "Netherlands",
	PL: "Poland",
	PT: "Portugal",
	RO: "Romania",
	SE: "Sweden",
	SI: "Slovenia",
	SK: "Slovakia",
}

const countries_currency = {
	AT: "EUR",
	BE: "EUR",
	BG: "EUR",
	CY: "EUR",
	CZ: "EUR",
	DE: "EUR",
	DK: "EUR",
	EE: "EUR",
	ES: "EUR",
	FI: "EUR",
	FR: "EUR",
	GB: "GBP",
	GR: "EUR",
	HR: "EUR",
	HU: "EUR",
	IE: "EUR",
	IT: "EUR",
	LT: "EUR",
	LU: "EUR",
	LV: "EUR",
	MT: "EUR",
	NL: "EUR",
	PL: "EUR",
	PT: "EUR",
	RO: "EUR",
	SE: "EUR",
	SI: "EUR",
	SK: "EUR",
}

const tax_rates = {
	AT: 20,
	BE: 21,
	BG: 20,
	CY: 19,
	CZ: 21,
	DE: 19,
	DK: 25,
	EE: 20,
	ES: 21,
	FI: 21,
	FR: 20,
	GB: 0,
	GR: 24,
	HR: 25,
	HU: 27,
	IE: 23,
	IT: 22,
	LT: 21,
	LU: 17,
	LV: 21,
	MT: 18,
	NL: 21,
	PL: 23,
	PT: 23,
	RO: 19,
	SE: 25,
	SI: 22,
	SK: 20,
}

const calculatePrice = (product, country, addons) => {
	const country_name = countriesMap[country]
	const currency = countries_currency[country]
	const currency_symbol = currency_symbols[currency]
	const product_name = products[product]
	const tax_rate = tax_rates[country]
	const product_price = price_list[currency][product]
	const annual_product_price = parseInt(product_price) * 10 + 9
	const addons_active = []
	let addon_prices = 0
	let annual_addon_prices = 0

	for (const addon of Object.keys(addons)) {
		if (addon === "backupsplus" && !addons["backupsplus"]) continue
		if (addon === "prioritysupport" && !addons["prioritysupport"]) continue
		let quantity = 1
		if (addon === "extrawikis" && addons["extrawikis"] !== 1) {
			quantity = addons["extrawikis"]
		} else if (addon === "extrastorage" && addons["extrastorage"] !== 1) {
			quantity = addons["extrastorage"]
		} else if (
			addon === "prioritysupport" &&
			(product === "corporate" || product === "vps")
		) {
			addons_active.push(addon_names[addon])
			quantity = 0
		}
		if (quantity > 0) {
			addons_active.push(addon_names[addon])
		}
		addon_prices += addons_price_list[currency][addon] * quantity
		annual_addon_prices += addons_price_list[currency][addon] * 12 * quantity
	}
	const price_before_tax =
		Math.round(((product_price + addon_prices) / 100) * 100) / 100
	const annual_price_before_tax =
		Math.round(((annual_product_price + annual_addon_prices) / 100) * 100) / 100
	const price_after_tax =
		Math.round((price_before_tax + (price_before_tax * tax_rate) / 100) * 100) /
		100
	const annual_price_after_tax =
		Math.round(
			(annual_price_before_tax + (annual_price_before_tax * tax_rate) / 100) *
				100
		) / 100

	return {
		product_name: product_name,
		country_name: country_name,
		country_code: country,
		tax_rate: tax_rate,
		addons: addons_active,
		currency: currency,
		currency_symbol: currency_symbol,
		price_before_tax: price_before_tax,
		annual_price_before_tax: annual_price_before_tax,
		price_after_tax: price_after_tax,
		annual_price_after_tax: annual_price_after_tax,
	}
}

// const res = calculatePrice("pro", "BE", {
// 	"backupsplus": true,
// 	"prioritysupport": true,
// 	"extrawikis": 1,
// 	"extrastorage": 1
// })
// console.log(res)
export default calculatePrice
