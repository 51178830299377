const { countryCodeEmoji } = require('country-code-emoji')

const GeolocationSnippet = ({ locationInfo }) => {
    let regionNames = new Intl.DisplayNames(['en'], {type: 'region'})

    return (
        <div>
            {locationInfo.country_code &&
			locationInfo.country_code !== "GB" &&
			locationInfo.country_code !== "CH" &&
			locationInfo.country_code !== "NO" &&
			locationInfo.country_code !== "IS" &&
			locationInfo.country_code !== "LI" &&
            !locationInfo.in_eu &&
                <div>
                    <p>
                        You are accessing MyWikis.eu from {locationInfo.country_name && countryCodeEmoji(locationInfo.country_code) + ' '}{locationInfo.country_name ? regionNames.of(locationInfo.country_code) : 'some place'}.
                    </p>
                    <p>
                        This website is intended for residents and organizations of the 🇪🇺 European Union and European Economic Area.
                    </p>
                    <p>
                        Visit our worldwide website at <a href="https://www.mywikis.com">MyWikis.com</a>
                    </p>
                </div>
            }
        </div>
    )
}

export default GeolocationSnippet