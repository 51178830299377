import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'

i18n.use(LanguageDetector).use(initReactI18next).use(HttpBackend).init({
    ns: ['eu-translation', 'translation'],
    defaultNS: 'eu-translation',
    fallbackNS: ['translation'],
    backend: {
        backendOptions: [{
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        }]
    },
    fallbackLng: 'en',
    debug: true,
    detection: {
        order: ['localStorage','navigator','querystring', 'cookie'],
        cache: ['localStorage','cookie']
    },
    interpolation: {
        escapeValue: false
    }
})

export default i18n