import React from "react"
import Helmet from "react-helmet/es/Helmet"
import "./Terms.css"

const Terms = () => {
	return (
		<div>
			<Helmet>
				<title>GENERAL TERMS AND CONDITIONS | MyWikis Europe | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">GTC</div>
			<div className="terms-container">
				<h1>MyWikis Europe GENERAL TERMS AND CONDITIONS</h1>
				<p>Version dated: 19 September 2023</p>

				<p><h2>1.	General</h2></p>
				<p>1.1.	Mag. Bernhard Krabina with the business address Brüder-Albert-Gasse 3/RH8, 1230 Vienna, Austria, VAT No. ATU48046109 ("MyWikis Europe", "We", or "Us"), offers various services, which are described in more detail in section 2.
				</p>
				<p>1.2.	These General Terms and Conditions ("GTC") apply to all services or offerings that We provide and make available to Clients ("You"). Our Services are available exclusively to entrepreneurs as defined by the Consumer Protection Act ("KSchG"); consumers are not permitted to use our Services and are excluded from them. The details of our Services are agreed in separate Service Contracts ("Service Contract"). In the event of any contradictions between the Service Contract and the GTC, the provisions in the Service Contract take precedence.
				</p>
				<p>1.3.	Your general terms and conditions will not become part of the contract, even if We do not expressly object to them.
				</p>
				<p><h2>2.	Services, Authorized Users</h2>
				</p>
				<p>2.1.	We essentially offer SaaS (software-as-a-service) services via the medium of the internet. The subject matter of the contract is (i) the provision, pre-configuration by Us, ongoing operation and maintenance of the MediaWiki Software ("Software"), and (ii) the granting of storage space ("Hosting") on our servers. The Software and Hosting are collectively referred to as the "Service(s)". You can therefore use our Service to operate one or more wikis yourself. The specific features of the Service are set out on our Website www.mywikis.eu ("Website") under [link], to the extent that they are not regulated in the Service Contract or the GTC.
				</p>
				<p>2.2.	We offer different service packages on our Website. The service packages, the associated specific scope of services (e.g., different number of available wikis, different version of the Software, varying storage space for Hosting), and the costs/fees for the specific service package are based on the description or the information on the Website. You can also select different "Privacy Levels" as part of the service packages, which are described in more detail on the Website: You decide whether the wiki can be read and changed/modified by all Users or only by Authorized Users (see subsection 2.4) and how new accounts can be created for the wikis. The respective choice determines whether the wiki is publicly accessible (or modifiable) by everyone via the internet or not.
				</p>
				<p>2.3.	The Services according to subsection 2.1 also include any additional (online) services that We make available to You in connection with the Service Contract and as a function of the service package selected (e.g., access to the self-service portal panel.mywikis.eu ["Client Panel"], online help, back-up service, maintenance/support as specified in section 8. The specific content of these Services is also detailed on the Website, unless otherwise specified in the Service Contract or the GTC.
				</p>
				<p>2.4.	You can use the Service for both yourself and for Authorized Users within the terms of the Service Contract. The definition of "Authorized User" is determined by the Privacy Level chosen and your settings in the Services: This can either include (i) potentially anyone (for example, in the case of a wiki that can be read or edited by anyone) or (ii) only specific individuals (chosen by You). To be clear: You are always an Authorized User. Depending on the applicable Privacy Level, third parties (other than Authorized Users) are not permitted to use the Service without our prior written consent. 
				</p>
				<p>2.5.	In all cases, all Users of the Services are attributed to You, and You will be responsible and liable for the acts/omissions of the Users of your Services, whether or not they are Authorized Users (hereinafter collectively referred to as "Users"). To be clear: Depending on the context, the term Users hereinafter also includes You. 
				</p>
				<p>2.6.	You are required to inform all Users about the conditions, rights, and obligations related to using the Services and to instruct and require the Users to comply with the provisions, requirements, and obligations of the Service Contract or the GTC in a timely manner before they begin using the services. In particular, the requirements related to usage rights in section 5 and Content Data in section 6 must be imposed on the Users accordingly.
				</p>
				<p>2.7.	In addition, further services can be mutually agreed between You and Us, either within the framework of the Service Contract or at a later point in time. These services will then also be included in the Services outlined in subsection 2.1. To be clear: If such services are subsequently agreed upon after conclusion of the Service Contract, these GTC, which constitute a framework contract for all agreements concluded between the same contracting parties, will also apply in all such cases.
				</p>
				<p>2.8.	We are entitled to engage third parties or subcontractors to fulfill our contractual obligations. Naturally, in such cases, We also guarantee delivery of the  Services as agreed in the contract.
				</p>
				<p><h2>3.	Order process, conclusion of contract</h2>
				</p>
				<p>3.2.	The Services or service packages listed on the Website do not constitute a binding offer on our part. Only when You place an Order on the Website do You make a legally binding contractual offer ("Order") to which You are bound. By placing the Order, You confirm that You are fully capable of conducting business and representing the Client, and if the Client is not a natural person, that You are also authorized to act on the Client’s behalf. We will provide an electronic and automated confirmation of your Order with Us promptly, typically on the same day. However, the confirmation of receipt of the Order itself does not yet constitute an acceptance of your Order by Us. 
				</p>
				<p>3.3.	We will accept your Order, at our discretion, either by email or by providing the Service. Upon our acceptance, the relevant Service Contract will come into effect. We reserve the right to accept or refuse an Order at our discretion. There is no entitlement to the conclusion of a Service Contract, and We are not required to offer an explanation for not entering into one.
				</p>
				<p>3.4.	We reserve the right to make reasonable changes to the Service Contract (or these GTC, including the Services and service packages) retroactively and unilaterally, particularly when such changes are necessary to remedy any equivalence disruptions that arise later or to conform to changing legal or technical conditions. We will inform You of any changes by notifying you of the content of the amended provisions. The amendment will become a part of the contract unless You object in writing within four weeks of receiving notification of the changes. If, within four weeks of receiving notification of the changes, You object to their incorporation into the contractual relationship, We reserve the right to terminate the Service Contract with immediate effect. Updates/upgrades, which are regulated in subsection 8.6, do not constitute such a change.
				</p>
				<p>3.5.	You will notify Us of any changes to your data processed by Us in connection with the conclusion of the Service Contract (e.g., name/company, address, email address, account details, VAT number, etc.; hereinafter referred to as "Client Data"). Until receipt of such notification, any communication sent to the last email address or physical address known to Us will be deemed received by You. 
				</p>
				<p>3.6.	The Service Contract (incl. the GTC) constitutes the entire agreement between You and Us; there are no (oral) side agreements.
				</p>
				<p><h2>4.	Technical requirements for use of the Services</h2>
				</p>
				<p>4.1.	 Users must create or meet certain technical requirements in their system environment to enable US to provide the Service to them and in order for the respective User to be able to use the Service or the wiki. These requirements include having an up-to-date operating system, uninterrupted internet access, and the installation of an up-to-date web browser. If these technical requirements are not met, the Service cannot be used. 
				</p>
				<p>4.2.	For more information about technical requirements, please consult our Website at [link].
				</p>
				<p>4.3.	We would like to point out that any changes in the Users' system environment may result in the Service being unavailable for use or no longer being available for adequate use. 
				</p>
				<p><h2>5.	Rights of use</h2>
				</p>
				<p>5.1.	The Software is what is known as an open source software and is subject to the license conditions of the "GNU General Public License" in version 2 of June 1991 (hereinafter referred to as "GPLv2"), which are available at the following link: https://www.gnu.org/licenses/old-licenses/gpl-2.0.html. The copyright notices of the Software are available at the following link: https://gerrit.wikimedia.org/g/mediawiki/core/%2B/HEAD/CREDITS. The Software's source code can be downloaded from the following link: https://github.com/wikimedia/mediawiki
				</p>
				<p>5.2.	We must explicitly draw your attention to the following with regard to the Software referred to in subsection 5.1: "This program is free software; You can redistribute it and/or modify it under the terms of the GNU General Public License as published by the Free Software Foundation; either version 2 of the License, or (at your option) any later version. This program is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU General Public License for more details. You should have received a copy of the GNU General Public License along with this program; if not, write to the Free Software Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA 02110-1301, USA."
				</p>
				<p>5.3.	Regarding the Software itself, Authorized Users are granted, among other things, a free and non-exclusive right of use. Free usage pertains exclusively to the corresponding license grant but does not extend to the other Services outlined in the Service Contract or GTC (such as Hosting, pre-configuration, operation, support, etc.). 
				</p>
				<p>5.4.	With respect to the remaining Services (excluding the Software), We grant You and the Authorized Users each the non-exclusive and non-transferable right to use the Service for your internal business purposes (in accordance with the Privacy Levels) for the duration of the Contract. The Authorized User, except in the case of a pure open-source software license, is not permitted to confer rights related to the Services to third parties, to transfer, assign, sublet, sublicense, or provide Services to third parties in any other way, in each case irrespective of whether in part or in full, whether for compensation or free of charge. 
				</p>
				<p>5.5.	We will provide You with the storage space specified in the Service Contract, with the provisions on use in subsection 5.4 applying by analogy.
				</p>
				<p>5.6.	You are obliged to ensure at all times that the use of the Services (by You or the Users) remains within the scope of the agreed Services (or service packages). If You (or the Users) use the Services You procured in such a way that the Service (subsequently) turns out to be insufficient (e.g., You exceed your storage allowance; "Overage"), (i) We will inform You of this and (ii) automatically upgrade You (subject to availability) to the next service level, which will also be subject to higher fees/rates immediately from the time of the upgrade. You already agree to this and to the higher fee, with the higher fee being based on the fee/rate lists which were available or published on the Website at the time of Overage. The normal termination dates are not affected by such a change.
				</p>
				<p>5.7.	The use of the Services is only permitted for humans; other uses (such as through bots, AI applications, crawlers, scrapers, data mining) are prohibited. 
				</p>
				<p>5.8.	The Services are not suitable for media streaming or file sharing services, and such use is prohibited. If your usage exceeds the 99th percentile of the usage of all Users of our Services for the operation of wikis, We will inform You thereof, and You will be obligated to either promptly reduce your usage accordingly following the notification or to enter into a separate agreement with Us within a reasonable timeframe. Failure to do so, may result in both parties having the right to terminate the Contract for cause (cf. subsection 11.3). For the avoidance of doubt, such a separate agreement is also subject to these GTC.
				</p>
				<p>5.9.	Users are not permitted to use or exploit any of our or MyWikis LLC's (9355 John W. Elliott Dr., Ste 25, Frisco, TX 75033, USA; "MyWikis LLC") intellectual property rights, patents, trademarks, designs, or other IP rights, such as logos and brands, in connection with the Services or the Website, except as permitted by law. To be clear: Just "browsing" or "surfing" the Website is permissible.
				</p>
				<p><h2>6.	Content Data</h2>
				</p>
				<p>6.1.	The data (which may include text, audio, video, graphics/photographs, or other material) entered and stored or processed by Users in the respective wiki or in the Service, which may also include personal data, is referred to as "Content Data". The User is responsible for entering and maintaining the Content Data. The Content Data may be viewed, edited, used, and otherwise processed by other Authorized Users (depending on the Privacy Level).
				</p>
				<p>6.2.	You must ensure at all times that all Content Data complies with all (i) (domestic and foreign) applicable legal and/or regulatory requirements applicable to the Service and/or the Client and/or Users. 
				</p>
				<p>6.3.	Each User is obligated to check the Content Data for viruses or other harmful components before transmitting it to the Services and to use local antivirus programs that are up to date with current technology standards. The use of viruses, spyware, malware, or similar in association with the Services is prohibited.
				</p>
				<p>6.4.	The Content Data must comply with the specifications in subsection 10.3. Furthermore, the User undertakes not to store, share, send, or otherwise process any Content Data in the Services whose provision, publication or use violates applicable law (e.g., trade and business secrets, data protection law) or agreements with third parties. In particular, the User agrees not to store, share, transmit, or otherwise process any Content Data that is offensive, harassing, threatening, or reputation-damaging, that glorifies violence, is inflammatory, or extortionate, that promotes Neo-Nazi ideology, that is racist, pornographic, obscene, terrorist, or otherwise (criminally) illegal, or that infringes the copyright, or the trademark rights of third parties, or third-party rights to a name, or personal rights of third parties (e.g., operation of file-sharing tools). Users are also prohibited from collecting other usernames or email addresses within the scope of the Services or from using these for advertising purposes (in particular for spam, mass emails, chain letters, etc.). Additionally, the storage, transmission, or making available of worms, viruses, Trojans, or other malware is prohibited; the concealment of the identity of individuals engaged in illegal activities is also prohibited. Scanning other people's networks or other people's IP addresses as well as manually changing hardware addresses (MAC) or using forged/manipulated IP addresses is likewise prohibited. Furthermore, any attempt to illegally gain access to our servers (or those of our subcontractors) or to the computers of other internet users to cause them damage or to impair their accessibility or performance in any way is prohibited. Users are also prohibited from using the Services in any way to compete with Us or MyWikis LLC (e.g., by offering their own wiki services). You fully indemnify and hold Us harmless in all cases for the actions/inactions of the Users.
				</p>
				<p>6.5.	If Users discover or suspect that certain Content Data violates subsections 6.2 or 6.4, they will immediately notify Us of this fact using the contact details provided on the Website or at https://panel.mywikis.eu/contact.php
				</p>
				<p>6.6.	If We (or our subcontractors) receive any notices/requests from the authorities to remove any Content Data, We will forward them to You. If You do not immediately comply with such notices, We can, at our sole discretion, remove such Content Data and/or terminate the Service Contract with You early for cause. In the event of (i) imminent danger, (ii) a mandatory legal obligation, (iii) legally binding government instructions, or (iv) government instructions without suspensive effect, We can in each case waive prior information and immediately remove the Content Data and/or terminate the Service Contract early.
				</p>
				<p><h2>7.	(Cooperation) Duties</h2>
				</p>
				<p>7.1.	You will ensure that use of the Services complies with all (i) applicable legal and/or regulatory requirements (both domestic and foreign) that apply to the Service and/or the Client or Users, and (ii) technical, organizational, and security requirements during the term of this Service Contract. Use of the Services should be undertaken in a standard manner so that the Services are not intentionally subjected to an abnormal load or stress. An abnormal load specifically refers to the use of more than 5% of our total available resources, without having entered into a corresponding agreement with Us, in which case subsection 5.8 applies by analogy.
				</p>
				<p>7.2.	Authorized Users are required to keep confidential and to securely store the login credentials (username, password) needed for using the Services, and to take suitable precautions to prevent unauthorized access by third parties. Authorized Users must restrict use of their Service login credentials to their personal use only. The Client is required to ensure that only Authorized Users use the Services. To be clear: This provision refers exclusively to the Service login credentials, not to the login credentials for your wikis.
				</p>
				<p>7.3.	You are not permitted (i) to use the Service in any manner that causes or is likely to cause damage to the Service or impairment of the availability or accessibility of the Service, or (ii) to use the Service in any manner that is unlawful, illegal, fraudulent, abusive, or harmful.
				</p>
				<p>7.4.	You are required to report any unauthorized or improper access to the Services or to access/login credentials as well as any other security breaches (e.g., hacker attacks, etc.) to Us immediately after becoming aware of them. You will also inform Us immediately if You have any suspicion of such breaches.
				</p>
				<p>7.5.	Setting up, securing, and maintaining an internet connection is solely the responsibility of the User; the User is responsible for any related expenses.
				</p>
				<p>7.6.	Subsection 6.4 also applies by analogy to the choice of username and of the wiki’s name or of what is referred to as the wiki ID during the ordering process. In this context, We reserve the right to change inappropriate wiki names, wiki IDs, or usernames at our discretion, to which You agree.
				</p>
				<p>7.7.	You are required to fulfill all (participation) obligations without compensation and without requiring specific instructions from Us. The (participation) obligations are part of your primary contractual duties. Any consequences resulting from a (participation) obligation that is not performed or not performed in a timely manner are your sole responsibility.
				</p>
				<p><h2>8.	Maintenance, availability & support</h2>
				</p>
				<p>8.1.	We will provide You with maintenance and support services during the term of the Service Contract, which are described in more detail on the Website at [Link].
				</p>
				<p>8.2.	The Service is provided "as is". During the term of this Contract, We will maintain the Service as set forth herein by providing bug fixes, stability and performance enhancements, and updates (Maintenance Services) as We deem appropriate. Where practicable and possible, maintenance of the Services will be provided with prior notice; no prior notice is required in the event of imminent danger.
				</p>
				<p>8.3.	We do not guarantee continuous and uninterrupted availability and accessibility of the Service, but We will endeavor to keep the Service available 24 hours a day, 7 days a week. We aim and endeavor to provide You with 99% availability of the Service on an annual average from Monday to Friday (excluding public holidays in Austria) from 8:00 am to 6:00 pm (CET).
				</p>
				<p>8.4.	To be clear: Downtime caused directly or indirectly by any of the following causes will not be deemed a breach of this Agreement: (i) a force majeure event (e.g., war, epidemic, natural disaster, labor strike), (ii) misuse of the Service by You or Users, (iii) failure or breakdown of the internet (particularly internet service provider/telecom provider outages, power outages, cable damage), (iv) disruption or failure of the Client's or the Users' computer systems or networks, or (v) scheduled maintenance.
				</p>
				<p>8.5.	We can restrict the availability of the Service or specific areas or features of the Service if it is appropriate to do so in view of capacity limits, the security or integrity of the infrastructure and servers, or to perform maintenance activities.
				</p>
				<p>8.6.	If We provide any updates/upgrades (which are enhancements and/or new or additional features of the Services), such updates/upgrades will be subject to the Service Contract. You agree that We have no obligation to continue to develop or improve the Service, to provide updates, or to provide new features. We are, however, authorized to implement new elements as part of and/or as additions to the Service at any time and at our sole discretion, provided that no essential feature of the existing Service is subsequently removed. If essential components of the Service are subsequently removed, subsection 3.4 will apply by analogy.
				</p>
				<p>8.7.	You can also obtain further information about the Services through the Client Panel. Additionally, you can send us support requests using the format provided in the Client Panel. We will endeavor to answer and resolve such support requests as quickly as possible, but cannot promise any response and/or resolution times in this context.
				</p>
				<p>8.8.	You are obligated to assist in the identification of defects, problems, and other Malfunctions ("Malfunctions"). In particular, You are required to provide Us with verifiable documents or information on the nature and occurrence of deviations from the Service or other Malfunctions and to describe how the Malfunction manifests, its impact, and the circumstances under which it occurs. At our request, You will also be required to name a contact person authorized to make decisions. If necessary to remedy the Malfunction, You will grant Us or our subcontractors remote access to your systems and take all necessary steps to cooperate.
				</p>
				<p><h2>9.	Rates/fees, terms of payment, stability of value</h2>
				</p>
				<p>9.1.	In return for the use of the Service, maintenance, support, and the organization of the Hosting and the other services agreed in the Service Contract, You will pay Us the agreed fee. The fee is based on the agreed service package and is described in more detail on the Website. All amounts stated on the Website are, unless the context otherwise requires, exclusive of applicable taxes, which will be added to these amounts and are your responsibility to pay. The exact payment terms (monthly/annually, payment method) will be further specified during contract conclusion.
				</p>
				<p>9.2.	The Website offers the option to select different currencies. You are required to select the currency that is the legal tender at your (place of) residence (e.g., GBP in England, EUR in Austria). Please note that all payments may be subject to (foreign) transaction fees, which are your responsibility to pay.
				</p>
				<p>9.3.	Payment can be made using the method specified on the Website at the time of the Order; in the event of subsequent renewals/changes, the payment methods available at that time will apply. If You use a credit card, You are obligated to provide all data accurately and completely and to ensure that the data remains up to date so that We can make the appropriate withdrawals for recurring payments.
				</p>
				<p>9.4.	We will upload the invoices to the Client Panel for you to retrieve and print. The invoices also state the respective payment due date; if no due date is specified, payments are due within 14 days of the invoice being made available by Us.
				</p>
				<p>9.5.	If You are (partially) in default of any payment under the Service Contract, We reserve the right (i) to charge default interest in accordance with section 456 Austrian Commercial Code (UGB) and (ii) to suspend access to the Service until You have satisfied all outstanding claims (including claims for default interest).
				</p>
				<p>9.6.	It is expressly agreed that the value of the fees/rates will remain stable. The consumer price index 2015 published monthly by Austria's Federal Statistical Office (Statistics Austria), or an equivalent index officially replacing it, will be used to calculate the value stability. The CPI number applicable at the time the Service Contract is concluded will serve as the reference value. If this CPI changes by more than 3% for the first time in a month during the agreed contract term, We have the right, but not the obligation to apply the entire adjustment (not just that which exceeds/falls short of the 3%) starting from that month, and We will inform You accordingly. In the event of the adjustment of rates, the value on which the adjustment was based will be considered the new reference point for future changes. We have the right, but not the obligation, to implement such changes immediately and may also retroactively bill for such adjustments.
				</p>
				<p>9.7.	Regardless of the stability of value mentioned in subsection 9.6, We reserve the right to adjust rates at any regular termination date in accordance with the rates/fees applicable at the time and stated on the Website. If You disagree with such a change, You have the option of immediate termination, which You must exercise within 4 weeks of receiving notice of the rate increase, otherwise the right to immediate termination is forfeited and your consent to the rate adjustment is deemed given.
				</p>
				<p>9.8.	Rate adjustments beyond the period of stable value (subsection 9.6) or beyond the possibility of adjustment on the date of ordinary termination (subsection 9.7) remain at our discretion. In particular, if technical or legal requirements necessitate an adaptation of the Services, incurring additional costs, We have the right to increase rates accordingly. If You disagree with such rate adjustments, the corresponding provision for special termination in subsection 9.7 applies accordingly.
				</p>
				<p><h2>10.	Data protection and data processing agreement</h2>
				</p>
				<p>10.1.	Each party is obligated to comply with the applicable data protection laws, in particular the GDPR and the Austrian Data Protection Act (DSG), with regard to the processing of personal data.
				</p>
				<p>10.2.	The purpose of the processing is to provide the Services to the Client. The duration of the processing depends on the term of the Service Contract. The legal basis for the specific processing is the fulfillment of the Service Contract in question and the associated performance of pre-contractual measures at the request of the Client.
				</p>
				<p>10.3.	The Client is not permitted to process all personal data within the scope of the Service. The Client will not use the Service for the processing of data under Art. 9 (1) GDPR and of data under Art. 10 GDPR, nor for processing operations falling under Art. 35 (1), (3) (a) to (c) or Art. 35 (4) GDPR. In the event that the Client processes such data regardless, We are entitled to terminate the Service Contract with immediate effect, with all other rights and claims remaining unaffected.
				</p>
				<p>10.4.	All other personal data within the meaning of Art. 4 (1) GDPR can be processed by the Client, provided that the Service Contract or the GTC do not impose any restrictions or requirements in this respect. The following types of personal data can therefore be processed: text data, video data/videos, image data/image files, audio data/audio files, access data, login credentials, certificates, value judgments, contract data, transaction data, tracking data, telephone numbers, activities, nationality, factual circumstances (income, debts, etc.), billing data, qualification/training, log data, program code, profile data and self-description, product data, preferences, planning and control data, physical characteristics, personal data, personal master data, location/position data, usage data, employee data, metadata, marketing data, wage and salary data, logistics data, client history, correspondence, contact data, communication data, identification numbers, IP addresses, interests, financial data, absences and time recording data, marital status, emails (incl. addresses), accounting data, credit data, order data, ownership characteristics, occupation, bank connection data, authentication data/passwords, ID data, information data, offer data, address data, and billing and payment data.
				</p>
				<p>10.5.	The Client can generally use the Services for any permitted purpose, provided that the Service Contract does not impose any restrictions/requirements (see especially subsection 6.4).
				</p>
				<p>10.6.	With regard to the Service, We act as data protection processor in relation to You. Under Art. 28 of the GDPR, We enter into the following data protection agreement (data processing agreement) with You, wherein the definitions of the GDPR apply in this respect (as well as elsewhere in the GTC and/or the Service Contract):
				</p>
				<p>10.6.1.	We process personal data exclusively on behalf of the Client. The mandate includes the Services in question. The object of the Service does not involve the primary use or processing of personal data by Us. The provision of the contractually agreed data processing will take place in the territory of the Republic of Austria, a Member State of the European Union, or in another contracting state of the European Economic Area, or in third countries, provided that specific conditions of the GDPR are met.
				</p>
				<p>10.6.2.	The data types or categories that are the subject of processing include both Client Data (subsection 3.5) as well as the data described in more detail above (subsection 10.3 ff). The processing affects the following categories of individuals: the Client, Authorized Users, and individuals affected in the context of the Content Data (e.g., Website visitors, members, employees/staff, suppliers, clients, contacts, subcontractors, newsletter subscribers, prospects, service providers, or other third parties). By concluding the Service Contract, the Client confirms the accuracy and completeness of the information regarding the data types and categories and the circle of data subjects. If there are any discrepancies in this regard, the Client will notify Us in writing in advance, and We may, at our discretion, decide whether to accept such discrepancies.
				</p>
				<p>10.6.3.	We process personal data only upon the Client's documented instructions, including with regard to the transfer of personal data to a third country or an international organization, unless We are required to do so by the law of the Union or the Member States to which We are subject; in such a case, We will notify the Client of these legal requirements before the processing, unless the relevant law prohibits such notification for reasons of substantial public interest. We are required to inform the Client promptly if We are of the opinion that an instruction violates data protection law. We are entitled to suspend the execution of the respective instruction until it is confirmed or amended in writing by the controller at our end.
				</p>
				<p>10.6.4.	We ensure that the persons authorized to process personal data have committed themselves to confidentiality or are subject to an appropriate legal obligation of confidentiality.
				</p>
				<p>10.6.5.	Within our area of responsibility, We design the internal organization to meet the requirements of data protection. In doing so, We implement technical and organizational measures to adequately secure the data against misuse and loss in order to comply with the requirements of the GDPR. We ensure the implementation of and compliance with all the technical and organizational measures necessary for this mandate in accordance with Art. 32 GDPR. The specific technical and organizational measures can be seen in Annex ./1. It is noted that both We and any (sub-)subcontractors employed have the technical capability to access the data, potentially without encryption, during the provision of the Service, which the Client expressly accepts as reasonable.
				</p>
				<p>10.6.6.	The parties agree that the technical and organizational measures may be subject to technical progress and further development. In this respect, We are permitted to implement alternative adequate measures. We will inform the Client of this on request and ensure that the security level of the established measures is not compromised. We are required to establish security in accordance with Art. 28 (3) (c), Art. 32 GDPR, in particular in connection with Art. 5 (1) and (2) GDPR. Overall, the measures to be taken are data security measures aimed at ensuring an appropriate level of protection in terms of confidentiality, integrity, availability, and resilience of the systems. Significant changes must be documented.
				</p>
				<p>10.6.7.	The Client agrees and expressly approves that We may engage the following companies as subprocessors to perform our contractually agreed services: (i) XPAX GmbH, Donaustraße 106, 3400 Klosterneuburg, (ii) easyname GmbH, Fernkorngasse 10/3/501, 1100 Vienna, (iii) Insight Technology Solutions GmbH, Am Europlatz 2 – Building G2, 1120 Vienna, (iv) Wasabi LLC, 675 E 2100 S Ste O Salt Lake City, 84106-5307 USA, (v) Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, L-1855 Luxembourg (vi) NESSUS GmbH, Fernkorngasse 10/3/501, 1100 Vienna, (vii) InterXion Österreich GmbH, Louis-Häflinger-Gasse 10, 1210 Vienna, and (viii) IPAX OG, Barawitzkagasse 10/2/11, 1190 Vienna. The Client agrees and expressly approves in general that the listed subprocessors may engage additional processors for the processing and that, in turn, these may engage additional subprocessors. Clients can obtain information about the specific additional processors from the listed subprocessors or from Us.
				</p>
				<p>10.6.8.	We will inform the Client of any intended change regarding the engagement or replacement of other processors or subprocessors, giving the Client the opportunity to object to such changes in writing, giving valid reasons, within three weeks of being informed. If the Client does not object to the subprocessor or processor in writing within the set time limit, the subprocessor or processor can be entrusted with the processing of personal data. If the (sub-)processor is absolutely necessary for Us to provide the Service, and the Client raises an objection, both parties to the contract are entitled to terminate the Service early with immediate effect for cause.
				</p>
				<p>10.6.9.	If We engage another (sub-)processor to perform certain processing activities for the Client, the same data protection obligations will be imposed on that (sub-)processor by way of a contract.
				</p>
				<p>10.6.10.	To the extent possible, We assist the Client with technical and organizational measures in fulfilling their obligations as a controller in the event of requests to exercise data subject rights in accordance with the applicable data protection law, in particular Chapter III of the GDPR. Furthermore, taking into account the information available to Us, We support the Client in complying with their obligations under the applicable data protection law, including Art. 32 to 36 of the GDPR. We are entitled to charge a reasonable fee for these (support) services.
				</p>
				<p>10.6.11.	The Client has full access to the personal data at all times, so that there is generally no need for our involvement, particularly for tasks such as correction, blocking, deletion, etc. However, the Client may engage Us to assist with or to carry out these tasks (to the extent that We can do so, both practically and technically), and such services are to be provided for separate and reasonable compensation, subject to our explicit approval.
				</p>
				<p>10.6.12.	The Client expressly declares that they can access and store all personal data at any time. The Client therefore declares that upon termination of the Service Contract, they will download all personal data themselves and instructs Us to delete all data remaining in our possession (including all personal data). The Client is obligated to have performed the necessary data backups independently no later than the day the Service Contract is terminated. The Client consents to the deletion of all data (rather than requesting its return). However, if there is an obligation under Union or Member State law to retain the personal data, We are entitled to continue storing it. If the Client requires any support services from Us in this context, these must be mutually agreed and paid for appropriately.
				</p>
				<p>10.6.13.	We will provide the Client with all the information needed to demonstrate compliance with the obligations set forth in this contract following a written request, within a reasonable period of time. The Client has the right to conduct audits and inspections, either personally or through an auditor authorized by them in writing, in the presence of our employees (or employees of our subprocessors). Such inspections can be carried out during regular business hours, without disrupting operations, upon prior notification and with reasonable advance notice. We make such inspections conditional on prior notification with reasonable advance notice and the signing of a confidentiality agreement. Furthermore, the Client is required to submit to the respective house rules of the respective data center. If the auditor commissioned by the Client is in a competitive relationship with Us (or with the respective subprocessors), We (or the subprocessors) have the right to reject this auditor and to insist on another auditor. Unless otherwise indicated for urgent reasons that need to be documented by the Client, such checks/inspections will not take place more frequently than every 12 months. We are entitled to charge a reasonable fee for related (support) services, which also includes any support fees for subcontractors (such as the costs of subprocessors); these costs must be borne by the Client.
				</p>
				<p>10.7.	Where government requests or instructions are transmitted to the Provider or their subcontractors to seek access to Client Data or Content Data, the Provider will notify the Client of such requests or instructions within a reasonable timeframe, provided that is reasonable and not prohibited by law or judicial/government instructions.
				</p>
				<p>10.8.	Please refer to our Website to view our Website's privacy policy.
				</p>
				<p><h2>11.	Term, termination</h2>
				</p>
				<p>11.1.	Unless otherwise stated in the Service Contract, the contractual relationship will be limited to one year from the time We begin providing the Service.
				</p>
				<p>11.2.	Both parties can terminate the Service Contract effective at the end of the year, unless otherwise provided for in the Service Contract. You must submit such termination notice to Us no later than 8 weeks before the end of the year, and We can also issue such termination notice to You up to 4 weeks before the end of the year. If no notice of termination is given by either contracting party, the Service Contract will be extended for an additional year on each occasion. The termination and extension provisions will apply in a similar manner for subsequent years.
				</p>
				<p>11.3.	Both parties may terminate the Service Contract early with immediate effect (without notice) and for cause. In particular, We have cause if You (i) fail to make due payments in full or (ii) breach material obligations under the Service Contract or the GTC, in particular the rules set out in sections 5, 6, and 7 of the GTC.
				</p>
				<p>11.4.	If We terminate the Service Contract early for cause, and such cause is attributable to You, all outstanding fees for the remaining limited term of the contract will become due for payment immediately, regardless of the immediate termination. If You terminate early and the cause is attributable to Us, there is no obligation to pay for contract periods after the time of termination.
				</p>
				<p>Example: The Service Contract runs for a limited period from 1 January to 31 December. If We terminate the Service Contract early on 10 May as described above, the fees for 11 May to 31 December will be due for payment immediately. If You terminate the Service Contract early on 10 May as described above, the fees for 11 May to 31 December will not be payable. Other rights and remedies will remain unaffected by this provision.
				</p>
				<p><h2>12.	Representations</h2>
				</p>
				<p>12.1.	We warrant to You that We are authorized to enter into the Service Contract and to fulfill the obligations under the Service Contract.
				</p>
				<p>12.2.	We warrant to You that the Services, when used by You in accordance with the Service Contract, do not infringe on anyone's intellectual property rights. If, contrary to expectations, We determine that your use of the Service in accordance with the Service Contract infringes on anyone's intellectual property rights, We may, at our own expense and discretion, (i) modify the Service so that it no longer infringes the relevant intellectual property rights; or (ii) procure for You the right to use the Service in accordance with the Service Contract. If both of these options are deemed unviable at our sole discretion, We reserve the right to terminate early.
				</p>
				<p>12.3.	You warrant to Us that You are authorized to enter into this Contract and to perform your obligations under this Contract.
				</p>
				<p>12.4.	These GTC provide a comprehensive understanding of all representations made by the parties regarding the Service. We do not provide You with any further representations regarding the Service.
				</p>
				<p><h2>13.	Warranty</h2>
				</p>
				<p>13.1.	We only warrant the features of the Service as described in the GTC and on the Website. Beyond that, We do not provide any warranties and exclude them to the extent permitted by law. This includes, in particular, any obligation to update in accordance with section 7 Austrian Consumer Warranty Act (VGG).
				</p>
				<p>13.2.	You acknowledge that software is never completely free from defects, errors, and bugs; and subject to the other provisions of the Service Contract, We make no warranties or representations that the Service is completely free from defects, errors, and bugs.
				</p>
				<p>13.3.	You acknowledge that software is never completely free from security threats; and subject to the other provisions of this Service Contract, We make no warranty or representation that the Service is completely secure.
				</p>
				<p>13.4.	You acknowledge that We do not provide any warranty of merchantability and fitness for a particular purpose.
				</p>
				<p>13.5.	You acknowledge and agree that the Service may be occasionally unavailable to allow for maintenance or other development activities. We will use reasonable endeavors to publish details of any unavailability on the Website and/or notify You in advance of such unavailability. Such planned unavailability will not entitle the Client to any claim against the Provider.
				</p>
				<p><h2>14.	Liability</h2>
				</p>
				<p>14.1.	We exclude any liability for damages caused by slight negligence.
				</p>
				<p>14.2.	Our liability for damages caused by gross negligence is limited to foreseeable damages typical for this type of contract and, for all cases of damage occurring within one year, is capped at the total amount of fees You actually paid to Us during that year.
				</p>
				<p>14.3.	Liability for loss of profit, consequential, indirect and immaterial damage is excluded to the extent permitted by law, except in the case of intent.
				</p>
				<p>14.4.	The liability for personal injury is not subject to any limitation.
				</p>
				<p>14.5.	The above liability provisions are exclusive and apply to both contractual and non-contractual claims. You therefore waive all further claims and rights under applicable law for breach of contract, breach of warranty, and in respect of any pre-contractual liability on our part.
				</p>
				<p>14.6.	The Client agrees to indemnify Us against any third-party claims arising from the unlawful use of the Services and the related services by the Client or that occurs with the Client's approval.
				</p>
				<p>14.7.	Liability claims become time-barred after 6 months from the date of knowledge of the damage and the party causing the damage.
				</p>
				<p><h2>15.	Indemnification</h2>
				</p>
				<p>You agree to indemnify and hold Us harmless from and against any and all claims (i) by your affiliated companies, Users of the Service, agents, employees, suppliers, distribution partners, business partners, collaborators, and any other legal or natural persons that may arise out of or in connection with the use of the Service, (ii) resulting from the misuse of the Service by You, Authorized Users or by third parties to whom You (or your Authorized Users) have granted access.
				</p>
				<p><h2>16.	Subcontractors</h2>
				</p>
				<p>We have the right to subcontract our obligations under the Service Contract (in whole or in part) without your consent. To the extent required by legal regulations, We will disclose these subcontractors to You. With regard to the data protection regulations and the subcontractors used, please refer to Section 10 of the Contract.
				</p>
				<p><h2>17.	Mention as reference client</h2>
				</p>
				<p>You grant Us the right to name You as a reference client using your company name and logo indefinitely and without charge, in particular but not limited to on our Website, other websites, media, and in print materials (both online and offline).
				</p>
				<p><h2>18.	Final provisions</h2>
				</p>
				<p>18.1.	If any provision of the Service Contract or the GTC is found by a court or other competent authority to be illegal and/or unenforceable, the other provisions of the Service Contract or the GTC will remain in force. If any unlawful and/or unenforceable provision would be lawful or enforceable if any part of it were deleted, that part will be deemed deleted and the rest of the provision will remain in effect (unless this would contradict the clear intention of the parties, in which case the entire provision in question will be deemed deleted).
				</p>
				<p>18.2.	The written form requirement is also satisfied by email or any other digital/electronic text form. We can also provide You with information/notifications through the Website or via the Client Panel.
				</p>
				<p>18.3.	You are not permitted to assign, transfer, encumber, license or otherwise deal with or dispose of any contractual rights or obligations under this Contract without our prior written consent, unless expressly permitted in the Service Contract or the GTC.
				</p>
				<p>18.4.	You are only entitled to offset our claims against your own claims if We have expressly recognized your claims in writing or if these claims have been legally established before a domestic court.
				</p>
				<p>18.5.	Voidance of the Service Contract on the grounds of laesio enormis, a fundamental change in circumstances, or error is excluded to the extent permitted by law.
				</p>
				<p>18.6.	The Service Contract constitutes the entire agreement between the Parties relating to the subject matter hereof and supersedes all prior agreements, understandings, and arrangements between the Parties relating to such subject matter. The GTC are provided in different languages. In the event of a conflict between different language versions of the GTC, the German language version of the GTC takes precedence.
				</p>
				<p>18.7.	The Service Contract (or the GTC) will be governed exclusively by and construed in accordance with Austrian substantive law, excluding the conflict-of-law rules (both national and international) and excluding the United Nations Convention on Contracts for the International Sale of Goods of 11 April 1980 (CISG).
				</p>
				<p>18.8.	For all legal disputes arising out of or in connection with the Service Contract (or the GTC), including issues related to its formation, validity, nullity, interpretation, performance, termination, as well as its pre-contractual and post-contractual effects, the parties agree to the exclusive jurisdiction of the court with subject matter jurisdiction located in 1010 Vienna.
				</p>
			</div>
		</div>
	)
}

export default Terms
