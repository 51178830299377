import React from "react"
import { Link } from "react-router-dom"
import "./Footer.css"
import logo from "../../images/logo.svg"
import GeolocationSnippet from "../GeolocationSnippet/GeolocationSnippet"
import { useTranslation } from "react-i18next"

const Footer = ({ locationInfo }) => {
	const { t } = useTranslation()

	return (
		<footer className="footer">
			<div className="footer-img-container">
				<img src={logo} alt="MyWikis Logo" height="70px" />
			</div>
			<div className="color-primary footer-name">
				<b>my</b>wikis <b>eu</b>rope
			</div>
			<div className="footer-links-container">
				<Link className="footer-link color-primary" to="/contact">
					Contact
				</Link>
				|
				<Link className="footer-link color-primary" to="/careers">
					Careers
				</Link>
				|
				<Link className="footer-link color-primary" to="/terms">
					Terms
				</Link>
				|
				<a
					className="footer-link color-primary"
					href="https://twitter.com/mywikis"
				>
					Twitter
				</a>
				|
				<Link className="footer-link color-primary" to="/news">
					News
				</Link>
				|
				<Link className="footer-link color-primary" to="/gdpr">
					GDPR
				</Link>
			</div>
			<p className="copyright-text">
				&copy; 2012&ndash;{new Date().getFullYear()} MyWikis Europe. All rights
				reserved.
			</p>
			<p className="copyright-text">
				{t('footer.proudly')}
			</p>
			<GeolocationSnippet locationInfo={locationInfo} />
		</footer>
	)
}

export default Footer
