import React from "react"
import { Link } from "react-router-dom"
import Helmet from "react-helmet/es/Helmet"
import PlansTable from "../../components/PlansTable/PlansTable"
import Addons from "../../components/Addons/Addons"
import CallToAction from "../../components/CallToAction/CallToAction"
import { useTranslation } from "react-i18next"
import "./Plans.css"

const Plans = () => {
	const { t } = useTranslation()

	return (
		<div className="plans-section">
			<Helmet>
				<title>Plans | MyWikis | Personalized wiki hosting</title>
			</Helmet>
			<div className="white-section heading-primary-light">{t("plans.heading.title")}</div>
			<div className="plans-outer-container">
				<div className="plans-container">
					<div className="need-help-container">
						<div className="heading-secondary-light">
							{t("create-a-wiki.heading.get-started-by")}
						</div>
						<p>
							{t("create-a-wiki.heading.need-help")}{" "}
							<Link to="/help-me-choose" className="button-link-white">
								Click here to answer a few questions
							</Link>{" "}
							and we'll give you our recommendation
						</p>
					</div>
					<PlansTable />
				</div>
			</div>
			<div className="addons-section">
				<div className="heading-primary-dark">Add-ons</div>
				<Addons />
			</div>
			<CallToAction />
		</div>
	)
}

export default Plans
