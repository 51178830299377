import manIcon1 from "../images/man-head-icon-1.svg"
import manIcon2 from "../images/man-head-icon-2.svg"
import manIcon3 from "../images/man-head-icon-3.svg"
import womanIcon1 from "../images/woman-head-icon-1.svg"
// import womanIcon2 from "../images/woman-head-icon-2.svg"
import { Trans } from "react-i18next"

const testimonials = [
	{
		text: <Trans i18nKey="landing.testimonials.testimonial1.text"/>,
		author: <Trans i18nKey="landing.testimonials.testimonial1.author"/>,
		icon: manIcon1
	},
	{
		text: <Trans i18nKey="landing.testimonials.testimonial2.text"/>,
		author: <Trans i18nKey="landing.testimonials.testimonial2.author"/>,
		icon: womanIcon1
	},
	{
		text: <Trans i18nKey="landing.testimonials.testimonial3.text"/>,
		author: <Trans i18nKey="landing.testimonials.testimonial3.author"/>,
		icon: manIcon2
	},
	{
		text: <Trans i18nKey="landing.testimonials.testimonial4.text"/>,
		author: <Trans i18nKey="landing.testimonials.testimonial4.author"/>,
		icon: manIcon3
	},
]

export default testimonials
