const addonsInfo = {
	"Backups Plus": {
		"bar color": "#72CAFF",
		"addon short description": <p>Get access to your backups daily.</p>,
		"addon main description": (
			<p>
				We already back up your wiki frequently, but do you also want a copy for
				peace of mind? If so, get Backups Plus so you can get a dump of your
				wiki every day.
			</p>
		),
		"cost": <span>$5</span>,
		"cost frequency": <span>per month</span>,
		"tax description": <span>excl. sales tax/VAT</span>,
	},
	"Elite Support": {
		"bar color": "#64CDEC",
		"addon short description": (
			<p>
				<span>VIP-level personalized technical and MediaWiki support.</span>
			</p>
		),
		"addon main description": (
			<p>
				Lightning fast, personalized support backed by our Elite Support service level
                agreement. Bypass our support system and contact us via a dedicated email and
                phone number. Call, text, or Zoom with our support team within hours.
			</p>
		),
		"cost": <span>Ask us</span>,
		"cost frequency": <span>for pricing details</span>,
		"tax description": <span> </span>,
	},
	"Extra Wikis": {
		"bar color": "#004D89",
		"addon short description": <p>Need more wikis than the included amount?</p>,
		"addon main description": <p>Upgrade your plan or add extra wikis.</p>,
		"cost": <span>$5</span>,
		"cost frequency": <span>per wiki per month</span>,
		"tax description": <span>excl. sales tax/VAT</span>,
	},
	"Extra Storage": {
		"bar color": "#3CBDFF",
		"addon short description": (
			<p>Need more storage than your included allotment?</p>
		),
		"addon main description": <p>Upgrade your plan or add more storage.</p>,
		"cost": <span>$0.10</span>,
		"cost frequency": <span>per GB per month</span>,
		"tax description": <span>excl. sales tax/VAT</span>,
	},
}

export default addonsInfo
