import React, { useState, useEffect } from "react"
import "./App.css"
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom"
import NavBar from "./components/NavBar/NavBar"
import Landing from "./pages/Landing/Landing"
import Features from "./pages/Features/Features"
import Plans from "./pages/Plans/Plans"
import CreateWiki from "./pages/CreateWiki/CreateWiki"
import HelpMeChoose from "./pages/HelpMeChoose/HelpMeChoose"
import PriceCalculator from "./pages/PriceCalculator/PriceCalculator"
import Login from "./pages/Login/Login"
import Terms from "./pages/Terms/Terms"
import GDPR from "./pages/GDPR/GDPR"
import PrivacyLaws from "./pages/PrivacyLaws/PrivacyLaws"
import FAQ from "./pages/FAQ/FAQ"
import Quarantined from "./pages/Quarantined/Quarantined"
import Suspended from "./pages/Suspended/Suspended"
import Maintenance from "./pages/Maintenance/Maintenance"
import Default404 from "./pages/Default404/Default404"
import Footer from "./components/Footer/Footer"
import i18n from "./i18n"
import { languageOptions } from "./utils/languageoptions"
import getGeolocationInfo from "./utils/geolocation"
import Extensions from "./pages/Extensions/Extensions"

const App = () => {
	const [locationInfo, setLocationInfo] = useState({})
	const [currentLanguage, setCurrentLanguage] = useState({
		value: "en",
		label: "English",
	})

	const changeLanguage = (e) => {
		i18n.changeLanguage(e.value)
	}

	useEffect(() => {
		i18n.on('languageChanged', (lng) => {
			for (const option of languageOptions) {
				if (option.value === lng) setCurrentLanguage(option)
			}
		})

		getGeolocationInfo(setLocationInfo)
	}, [])

	// Add Matomo tracking code for MyWikis.eu's Matomo instance
	useEffect(() => {
		var _mtm = window._mtm = window._mtm || [];
		_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
		var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
		g.async=true; g.src='https://analytics.mywikis.eu/js/container_Hvcu34Y5.js'; s.parentNode.insertBefore(g,s);
	}, [])

	// Add Matomo tracking code for Knowledge.Wiki's Matomo instance
	useEffect(() => {
		var _mtm = window._mtm = window._mtm || [];
		_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
		var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
		g.async=true; g.src='https://matomo.knowledge.wiki/js/container_DarcDdap.js'; s.parentNode.insertBefore(g,s);
	}, [])

	return (
		<Router basename="/" forceRefresh={true}>
			<div className="App">
				<NavBar
					currentLanguage={currentLanguage}
					languageOptions={languageOptions}
					changeLanguage={changeLanguage}
					setCurrentLanguage={setCurrentLanguage}
					locationInfo={locationInfo}
				/>
				<Switch>
					<Route exact path="/" component={Landing} />
					<Route path="/index">
						<Redirect to="/" />
					</Route>
					<Route path="/index.html">
						<Redirect to="/" />
					</Route>
					<Route path="/features" component={Features} />
					<Route path="/plans" component={Plans} />
					<Route path="/createawiki" component={CreateWiki} />
					<Route path="/help-me-choose" component={HelpMeChoose} />
					<Route path="/pricecalculator" component={PriceCalculator} />
					<Route path="/extensions" component={Extensions} />
					<Route
						path="/consulting"
						component={() =>
							window.location.replace(
								"https://www.mywikis.com/consulting"
							)
						}
					/>
					<Route
						path="/demo"
						component={() =>
							window.location.replace(
								"https://helpcenter.mywikis.wiki/wiki/Demo"
							)
						}
					/>
					<Route
						path="/contact"
						component={() =>
							window.location.replace(
								"https://panel.mywikis.eu/contact.php"
							)
						}
					/>
					<Route
						path="/sso-information"
						component={() =>
							window.location.replace(
								"https://helpcenter.mywikis.wiki/wiki/Single_Sign-On"
							)
						}
					/>
					<Route path="/login" component={Login} />
					<Route path="/gdpr" component={GDPR} />
					<Route path="/privacylaws" component={PrivacyLaws} />
					<Route path="/terms" component={Terms} />
					<Route path="/faq" component={FAQ} />
					<Route path="/404-quarantined" component={Quarantined} />
					<Route path="/404-suspended" component={Suspended} />
					<Route path="/503-maintenance" component={Maintenance} />
					<Route path="/" component={Default404} />
				</Switch>
				<Footer locationInfo={locationInfo} />
			</div>
		</Router>
	)
}

export default App
