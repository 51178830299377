import React from "react"
import Helmet from "react-helmet/es/Helmet"
import "./FAQ.css"
import { useTranslation } from "react-i18next"

const FAQ = () => {
    const { t } = useTranslation()

	return (
		<div>
			<Helmet>
				<title>Frequently Asked Questions about MyWikis Europe | MyWikis Europe | Personalized wiki hosting</title>
			</Helmet>
			<div className="gradient-section-light heading-primary-light">{t("faq.heading.title")}</div>
            <div class="generic-text-block">
				<div class="heading-secondary-dark"><p>General</p></div>
				<p><b>How is MyWikis.eu different from MyWikis.com?</b></p>
				<p>
					MyWikis.eu operates for the people and organizations of the European Union, European Economic Area, and Switzerland.{" "}
					Your wiki will be hosted on servers based entirely within the European Union. Your data will be fully protected by the GDPR.{" "}
					MyWikis.eu is governed by the laws of the European Union and the Republic of Austria. MyWikis.com will continue to be governed by{" "}
					the laws of the United States and the State of Texas.{" "}
					As of September 1, 2023, clients based in the United Kingdom are no longer eligible to sign up for MyWikis.eu due to Brexit-related{" "}
					VAT regulatory challenges. UK clients are instead asked to sign up on <a href="https://www.mywikis.com">MyWikis.com</a>.
				</p>
				<br />
				<p><b>Where are MyWikis.eu's servers?</b></p>
				<p>
					All of your data will be stored at rest in Vienna, Austria in the IP.ONE and Interxion datacenters. Enjoy super fast loading speeds{" "}
					when accessing your wiki from Europe while ensuring your wiki will be fast when accessed from the rest of the world.
				</p>
				<br />
				<p><b>What's the same and what's different?</b></p>
				<p>
					The features will be exactly the same whether you are using the original MyWikis.com platform or MyWikis.eu.{" "}
					The billing system and Client Panel are completely separate, and this is to your benefit: you get full GDPR{" "}
					compliance and can rest easy knowing your data is hosted within the EU.
				</p>
				<br />
				<p><b>Can I sign up for MyWikis.eu right now?</b></p>
				<p>
					Yes, we are now accepting signups for MyWikis Europe effective immediately! We are currently in open beta. You can {" "}
					<a href="https://www.mywikis.eu/createawiki">sign up for MyWikis.eu at MyWikis.eu/createawiki</a>.
				</p>
				<br />
				<p><b>If I am not based in the EU, EEA, or Switzerland, can I still use MyWikis.eu?</b></p>
				<p>
					No, you are not eligible to use MyWikis.eu. Please use <a href="https://www.mywikis.com">MyWikis.com</a>{" "}
					instead. 
				</p>
				<br />
				<p><b>If I am based in the EU, EEA, UK, or Switzerland, can I still use MyWikis.com?</b></p>
				<p>
					We highly encourage EU and EEA citizens to use MyWikis.eu because MyWikis.eu hosts your wiki in Europe.{" "}
					That brings you many benefits, including faster loading speeds and protection of your data under GDPR.{" "}
					MyWikis.eu complies strictly with GDPR, whereas MyWikis.com operates under U.S. law and is meant{" "}
					for non-EU and non-EEA jurisdictions.
				</p>
				<p>
					In some cases, it may be appropriate to host your wiki in the U.S. rather than Europe. This includes{" "}
					content that cannot be hosted on MyWikis.eu but can be hosted on MyWikis.com due to the difference{" "}
					in laws between the United States and European Union.
				</p>
				<br />
				<div class="heading-secondary-dark"><p>Billing</p></div>
				<p><b>What currencies will you accept for payment?</b></p>
				<p>
					Euros (€) only. MyWikis.eu does not accept U.S. dollars, pounds sterling, or Swiss francs&mdash;if you would like to pay in USD or GBP, please use MyWikis.com.
				</p>
				<br />
				<p><b>Will there be the same plans and pricing?</b></p>
				<p>
					Prices are in euros rather than dollars. This, of course, means pricing is different. Plans will also have
					different names, but each MyWikis.eu plan has an analogous counterpart MyWikis.com plan.
				</p>
				<br />
				<p><b>Do you accept non-euro currencies of EU countries?</b></p>
				<p>
					We are required to pay VAT in euros, even for countries which do not use the euro. Therefore, we can't accept anything{" "}
					that is not either the euro due to concerns of the fluctuation of currency exchange conversion rates.
				</p>
				<br />
				<div class="heading-secondary-dark"><p>Legal and GDPR</p></div>
				<p><b>Is MyWikis Europe GDPR compliant?</b></p>
				<p>
					Absolutely, we aim to be fully GDPR compliant.
				</p>
				<br />
				<p><b>Is MyWikis Europe (MyWikis.eu) a separate legal entity?</b></p>
				<p>
					MyWikis is a joint venture. We are closely partnering with a European partner to smoothly handle all local matters.{" "}
					MyWikis Europe uses the VAT number <b>ATU48046109</b>.
				</p>
				<br />
				<p><b>Will any of my personal information on the Client Panel still be stored in the U.S. or outside of the EU?</b></p>
				<p>
					All MyWikis.eu wikis and their data will always be stored inside the EU and will never leave the EU's borders without
					your consent. If any data is transferred between our servers, they will only be transmitted within the EU. We will
					strictly adhere to this policy to ensure your privacy, safety, legal reassurance, GDPR compliance, and peace of mind.
					(Please note we are only making reasonable guarantees for circumstances within our control. Emails, accessing your
					own data from outside of the EU, and data sent off of our servers are beyond our control and can't be guaranteed
					to stay within the EU.)
				</p>
				<br />
				<p><b>Is MyWikis.eu ran by the MyWikis.com team?</b></p>
				<p>
					To comply with GDPR, we have taken steps to ensure the technology and operating procedures remain the same between{" "}
					MyWikis.com and MyWikis.eu. Our European partners are trusted MediaWiki experts{" "}
					based in the European Union who are committed to MyWikis' mission to bring you the best MediaWiki hosting in the world.{" "}
					The result is a seamless experience that combines the best of both worlds: the MyWikis experience, the European way.
				</p>
				<br />
				<p><b>Can/will my data be stored in Switzerland, the United Kingdom, or any non-EU EEA countries?</b></p>
				<p>
					Although EEA countries have signed agreements to come into compliance with the European Union's GDPR regulations,{" "}
					we currently don't plan on storing data in non-EU EEA countries.{" "}
					We also will not be storing any data in the United Kingdom or Switzerland.
				</p>
				<br />
				<p><b>Are there any content that cannot be hosted on MyWikis.eu by virtue of GDPR?</b></p>
				<p>
					In addition to the obvious restriction that no content violating EU laws can be hosted on MyWikis.eu,{" "}
					we are additionally categorically prohibited by the GDPR from hosting any of the following personal data:{" "}
					racial or ethnic origin;{" "}
					sexual orientation;{" "}
					political opinions;{" "}
					religious or philosophical beliefs;{" "}
					trade-union membership;{" "}
					genetic, biometric, or health data;{" "}
					personal data relating to criminal convictions and offences.
				</p>
				<p>
					While GDPR allows derogations to be made to host such content, we have decided against accepting{" "}
					such content on MyWikis.eu wikis. Instead, you can use MyWikis.com, which is governed by{" "}
					U.S. laws and where some of what is considered personal data in the EU are in fact generally considered free{" "}
					speech under the First Amendment of the U.S. Constitution and are thus protected at{" "}
					a fundamental level of American law.
				</p>
				<br />
				<p><b>Are the Terms of Service different between MyWikis.com and MyWikis.eu?</b></p>
				<p>
					Due to the different jurisdictions in which MyWikis.com and MyWikis.eu operate, the{" "}
					Terms of Service (ToS) will differ accordingly.
				</p>
			</div>
		</div>
	)
}

export default FAQ
